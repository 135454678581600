<template>
  <div>
    <div class="help">
     <div class="item">
       <div class="help-item">
         <div class="item-left">
           <div class="left-title">
             帮助中心
           </div>
           <div class="left-content">
             专属顾问，专家支持，让您无后顾之忧
           </div>
         </div>
       </div>
       <div class="help-img">
         <img src="../../../public/helpcenter.png" alt="">
       </div>
     </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="scss">
.help {
  height: 500px;
  background: url("../../../public/centerbg.png");
  .item{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
    .help-img{
      padding-top: 50px;
    }
    .help-item {
      text-align: left;
      .item-left {
        width: 500px;
        float: left;
        margin-top: 180px;
        margin-left: 70px;

        .left-title {
          font-size: 54px;
          line-height: 54px;
          color: #fff;
        }

        .left-content {
          font-size: 26px;
          color: #fff;
          line-height: 28px;
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
